import React from 'react'
// import { Link } from "gatsby-plugin-intl"
// import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// import Button from "../components/Button"
// import Legend from "../components/Legend"
import { Grid, Row, Col } from 'react-flexbox-grid'
import Logo from "../components/Logo"
// import ScrollIntoView from 'react-scroll-into-view'

// import logoFull from "../assets/svg/logo-full.svg"
// import scrollDown from "../assets/svg/scroll-down.svg"
// import scrollTop from "../assets/svg/scroll-top.svg"
import bgEsper from "../assets/svg/bg-esperluette.svg"
// import bgEsperHome from "../assets/svg/bg-esperluette-home.svg"
// import pastImplan from "../assets/svg/pastilleJaune.svg"
// import pastInter from "../assets/svg/pastillemarron.svg"

// import SVGMap1200 from "../images/carte_1200px.svg"
// import { TooltipParis, TooltipVenise, TooltipTokyo } from "../components/MapTooltips.js"
// import { ZoneParis, ZoneVenise, ZoneTokyo } from "../components/MapZones.js"

const LegalNoticesPage = () => (
  <Layout>
    <SEO title="Mentions Légales" />
    <section className="section-wrapper" id="errordocument">
      <img className="bg-esper" src={bgEsper} alt="" />
      <Grid fluid>
        <Row className="hat">
          <Col mdOffset={1} xs={12} md={5}>
            <h1>Mentions légales</h1>
            <p>
              Le site bucy-associates.com est produit par la société :<br />
              <strong>Bucy & Associates</strong><br />
              <a href="https://goo.gl/maps/C74qp7Y8WCt2FGm76" title="" target="_blank" rel="noreferrer">47 rue de l'Université, 75007 Paris</a><br />
              T. <a href="tel://+33145488064" className="tel">+33(0)1 45 48 80 64</a><br />
              <a href="mailto:paris@bucy-associates.com" className="email">paris@bucy-associates.com</a>
            </p>
            <br />
            <h2 className="small">Conditions d'utilisation et limites de responsabilité</h2>
            <p>Bucy s'efforce d'assurer au mieux de ses possibilités, l'exactitude et la mise à jour des informations diffusées sur ce site, dont elle se réserve le droit de corriger, à tout moment et sans préavis, le contenu. Toutefois, Bucy ne peut garantir l'exactitude, la précision ou l'exhaustivité des informations mises à la disposition sur ce site.</p>
            <br />
            <h2 className="small">Liens</h2>
            <p>Le site peut inclure des liens vers d'autres sites Web ou d'autres sources Internet. Dans la mesure Bucy ne peut contrôler ces sites et ces sources externes, Bucy ne peut être tenu pour responsable de la mise à disposition de ces sites et sources externes, et ne peut supporter aucune responsabilité quant au contenu, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes. De plus, Bucy ne pourra être tenue responsable de tous dommages ou pertes avérés ou allégués consécutifs ou en relation avec l'utilisation ou avec le fait d'avoir fait confiance au contenu, à des biens ou des services disponibles sur ces sites ou sources externes.</p>
          </Col>
        </Row>
      </Grid>
    </section>
    <Logo />
  </Layout>
)

export default LegalNoticesPage
